import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import {
  Currency,
  CurrencyCollection,
} from "@planetadeleste/vue-mc-shopaholic";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<Currency, CurrencyCollection>
>;

@Component
export default class CurrenciesMixin extends Mixins(TypedModelMixin) {
  obModelClass = Currency;
  obCollection = new CurrencyCollection();
  obCollectionClass = CurrencyCollection;
  sRouteName = "currencies.list";

  created() {
    this.onCreated();
  }
}
